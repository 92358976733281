import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDbuM3jQhjiw6L6xSevMxAaUdqVJOCqLCg",
  authDomain: "kisystems-d4135.firebaseapp.com",
  projectId: "kisystems-d4135",
  storageBucket: "kisystems-d4135.appspot.com",
  messagingSenderId: "437990260744",
  appId: "1:437990260744:web:82260b011396c8086391bd",
  measurementId: "G-YR69ZYRRZP"
};
const app = initializeApp(firebaseConfig);
const app_aux = initializeApp(firebaseConfig, "Secondary");
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const auth_aux = getAuth(app_aux);
//export const analytics = null;