import React from 'react';
import {useEffect} from 'react';
import './style.css';
import logoKSmin from '../../images/logoKS_min.png';
import oferta from '../../images/servicios/oferta.png';
import forma from '../../images/servicios/forma.png';
import vA from '../../images/servicios/vA.png';
import vB from '../../images/servicios/vB.png';
import vC from '../../images/servicios/vC.png';
import designD from '../../images/servicios/designD.png';
import articulos from '../../images/servicios/articulos.png';
import cA from '../../images/servicios/cA.jpg';
import cB from '../../images/servicios/cB.jpg';
import cC from '../../images/servicios/cC.jpg';
import cD from '../../images/servicios/cD.jpg';
import {Link} from "react-router-dom";
import { analytics } from '../../Firebase.js';
import { logEvent } from "firebase/analytics";

const Servicios = () => {
    useEffect(() => {
        document.title ="Ki Systems | Servicios";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleServicios");

        Array.from(document.getElementsByTagName("html")[0].getElementsByClassName("animated")).forEach(e => e.style.visibility = "hidden");
        let observer = new IntersectionObserver((e) => {
            if(!e[0].isIntersecting) {
                observer.unobserve(e[0].target);
                console.log("se efectua");
                Array.from(e[0].target.nextElementSibling.getElementsByClassName("animated")).forEach(el => {
                    el.style.visibility = "visible";
                    el.classList.add("animate__animated");
                    if(el.classList.contains("fadeInUp")) { el.classList.add("animate__fadeInUp"); }
                    if(el.classList.contains("zoomIn")) { el.classList.add("animate__zoomIn"); }
                    if(el.classList.contains("fadeInLeft")) { el.classList.add("animate__fadeInLeft"); }
                    if(el.classList.contains("bounceIn")) { el.classList.add("animate__bounceIn"); }
                    if(el.classList.contains("heartBeat")) { el.classList.add("animate__heartBeat"); }
                });
            }
        }, { rootMargin: "-50%" });
        observer.observe(document.getElementById("oferta"));
        observer.observe(document.getElementById("ventajas"));
        observer.observe(document.getElementById("design-header"));
        observer.observe(document.getElementById("design"));
        observer.observe(document.getElementById("articulos"));
    });

    const galeryInfo = [
        ["url("+cA+")", "Diseños en todo tipo de formatos", "Desde tarjetas, presentaciones, afiches, brochures, lonas, pines y mucho más. Le ayudamos a cuidar la imagen de marca personal o corporativa que transmite"],
        ["url("+cB+")", "Diseño de interfaces", "¿Ya tiene una aplicación web o móvil funcional y desea actualizar su interfaz?<br/>Contáctenos y plasmemos su idea de imagen"],
        ["url("+cC+")", "Publicidad", "¿Tiene una campaña publicitaria en mente y quiere que tenga el impacto esperado?<br/>Construimos los diseños que atraerán las miradas de su público objetivo"],
        ["url("+cD+")", "Diseños educativos", "Trabajamos en sus guías, manuales de usuario, material didáctico...<br/>Con un enfoque minimalista, de fácil lectura y retención"],
    ];
    const choseGalery = (index, element) => {
        Array.from(document.getElementsByClassName("image-car")).map(e => e.classList.remove("selected"));
        element.target.classList.remove("animate__animated");
        element.target.classList.remove("animate__heartBeat");
        setTimeout(() => {
            element.target.classList.add("selected");
        }, 20);
        document.getElementById("varied").style.backgroundImage = galeryInfo[index-1][0];
        document.getElementById("varied").getElementsByTagName("h1")[0].innerHTML = galeryInfo[index-1][1];
        document.getElementById("varied").getElementsByTagName("p")[0].innerHTML = galeryInfo[index-1][2];
        document.getElementById("varied").getElementsByTagName("h1")[0].style.display = "none";
        document.getElementById("varied").getElementsByTagName("p")[0].style.display = "none";
        setTimeout(() => {
            document.getElementById("varied").getElementsByTagName("h1")[0].style.display = "block";
            document.getElementById("varied").getElementsByTagName("p")[0].style.display = "block";
        }, 200);
    };

    return (
        <React.Fragment>
            <nav>
                <div id='nav-bg'>
                    <div id='a'></div><div id='b'></div>
                    <div id='c'></div><div id='d'></div>
                    <Link id="logo" to='/'><img src={logoKSmin} /></Link>
                </div>
            </nav>

            <main>
                <div id='oferta'>
                    <div className='bg-anim-container'>
                        <div id='e'></div>
                        <div id='f'></div>
                        <div className='animate__animated animate__fadeInLeft' id='g'></div>
                    </div>
                    <div className='image-container'>
                        <img className='animate__animated animate__zoomIn animate__slow' src={oferta} />
                    </div>
                    <div className='info animate__animated animate__fadeIn'>
                        <h1 className='animate__animated animate__fadeInUp'>Desarrollo de software a la medida</h1>
                        <p className='animate__animated animate__fadeInUp anim-delay-500ms'>Atendemos las necesidades digitales de su negocio y le entregamos software de calidad, con garantía y totalmente personalizado</p>
                        <div className='elements'>
                            <div className='animate__animated animate__zoomIn anim-delay-800ms'>
                                <i className="fa-solid fa-laptop-code"></i>
                                <h2>Aplicaciones web</h2>
                            </div>

                            <div className='animate__animated animate__zoomIn anim-delay-1s-300ms'>
                                <i className="fa-solid fa-mobile-screen"></i>
                                <h2>Aplicaciones móviles</h2>
                            </div>

                            <div className='animate__animated animate__zoomIn anim-delay-1s-800ms'>
                                <i className="fa-solid fa-server"></i>
                                <h2>Servicios locales</h2>
                            </div>
                        </div>
                        <a href="https://wa.me/+522222380533" onClick={() => logEvent(analytics, "Click en contactanos")} className="animate__animated animate__bounceIn anim-delay-2s-300ms" style={{animationDuration: "1.3s"}} target="_blank">
                            Contáctanos
                            &nbsp;<b style={{fontSize:"1.5rem", fontWeight:"100"}}>|</b>&nbsp;&nbsp;
                            <i className="fa-brands fa-whatsapp"></i>
                        </a>
                    </div>
                </div>

                <div id='ventajas'>
                    <div className='bg-anim-container'>
                        <div id='i'></div>
                        <div id='j'></div>
                        <div id='k'></div>
                        <div id='l'></div>
                        <div id='m'></div>
                        <div id='n'></div>
                    </div>
                    <div className='info'>
                        <h2 className='animated fadeInUp'>¿Por qué elegir Ki Systems?</h2>
                        <h1 className='animated fadeInUp anim-delay-500ms'>Atención, confianza y garantía</h1><br/>
                        <p className='animated fadeInUp anim-delay-800ms'>Hacemos uso de metodologías de desarrollo dinámicas y adaptables,
                        en donde podrá supervisar la evolución del producto, obteniendo la solución digital que requiere en el menor tiempo posible</p>
                        <div className='detail'>
                            <div className='element animated zoomIn anim-delay-1s'>
                                <img src={vA} />
                                <h3>Prototipo gratis</h3>
                                <p>Le ofrecemos un prototipo gratuito de su aplicación antes de tomar una desición</p>
                            </div>
                            <div className='element animated zoomIn anim-delay-1s-500ms'>
                                <img src={vB} />
                                <h3>Sin anticipos</h3>
                                <p>Planes de pago flexibles y personalizados.<br/>Optimice sus recursos y elimine riesgos</p>
                            </div>
                            <div className='element animated zoomIn anim-delay-2s'>
                                <img src={vC} />
                                <h3>Garantía total</h3>
                                <p>Un año de garantía, incluye: soporte técnico, mantenimiento y modificaciones de bajo a medio impacto</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='forma'>
                    <div className='bg-anim-container'>
                        <div id='o'></div>
                        <div id='p'></div>
                        <div id='q'></div>
                    </div>
                    <div className='image-container'>
                        <img className='animated zoomIn' src={forma} />
                    </div>
                    <div className='info'>
                        <h2 className='animated fadeInUp'>Conoce nuestra</h2>
                        <h1 className='animated fadeInUp anim-delay-700ms'>Forma de trabajo</h1>
                        <div className='list'>
                            <div className='animated fadeInLeft anim-delay-1s-400ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Uso de ingeniería de requerimientos</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-1s-700ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Diseño y presentación de prototipo</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-2s'>
                                <i className="fa-solid fa-play"></i>
                                <p>Desarrollo del diseño funcional</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-2s-300ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Reuniones de seguimiento</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-2s-600ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Liberación del producto</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-2s-900ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Capacitación a usuarios</p>
                            </div>
                            <div className='animated fadeInLeft anim-delay-3s-200ms'>
                                <i className="fa-solid fa-play"></i>
                                <p>Periodo de soporte y garantía</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='tecnologias'>
                    <h2>Especializados en las principales tecnologías de desarrollo, aplicamos la que mejor se adapte a su proyecto</h2>
                    <div className='top-side'>
                        <div className='element' id='a-e-ts'><i className="fa-brands fa-java"></i></div>
                        <div className='element' id='b-e-ts'><i className="fa-brands fa-node"></i></div>
                        <div className='element' id='c-e-ts'><i className="fa-brands fa-js"></i></div>
                        <div className='element' id='d-e-ts'><i className="fa-brands fa-laravel"></i></div>
                        <div className='element' id='e-e-ts'><i className="fa-brands fa-react"></i></div>
                        <div className='element' id='f-e-ts'><i className="fa-brands fa-angular"></i></div>
                        <div className='element' id='g-e-ts'><i className="fa-brands fa-unity"></i></div>
                        <div className='element' id='h-e-ts'><i className="fa-brands fa-vuejs"></i></div>
                    </div>
                    <div className='down-side'>
                        <div className='element' id='a-e-ds'><i className="fa-brands fa-vuejs"></i></div>
                        <div className='element' id='b-e-ds'><i className="fa-brands fa-unity"></i></div>
                        <div className='element' id='c-e-ds'><i className="fa-brands fa-angular"></i></div>
                        <div className='element' id='d-e-ds'><i className="fa-brands fa-react"></i></div>
                        <div className='element' id='e-e-ds'><i className="fa-brands fa-node"></i></div>
                        <div className='element' id='f-e-ds'><i className="fa-brands fa-js"></i></div>
                        <div className='element' id='g-e-ds'><i className="fa-brands fa-laravel"></i></div>
                        <div className='element' id='h-e-ds'><i className="fa-brands fa-java"></i></div>
                    </div>
                    <div className='ls'></div>
                    <div className='rs'></div>
                </div>

                <div id='design-header'>
                    <div className='anim-text'>
                        <svg viewBox="0 0 1320 300">
                            <symbol id="s-text">
                                <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                                    DISEÑO GRÁFICO
                                </text>
                            </symbol>  
                            <use xlinkHref="#s-text" className="text"></use>
                            <use xlinkHref="#s-text" className="text"></use>
                            <use xlinkHref="#s-text" className="text"></use>
                            <use xlinkHref="#s-text" className="text"></use>
                            <use xlinkHref="#s-text" className="text"></use>
                        </svg>
                    </div>
                </div>

                <div id='design'>
                    <div className='detail'>
                        <div className='bg-image-container'></div>
                        <div className='info'>
                            <h1 className='animated fadeInUp'>Servicios integrales de diseño gráfico</h1>
                            <p className='animated fadeInUp anim-delay-700ms'>
                                Desde <b>publicidad</b> para su negocio o campaña, hasta una estrategia de <b>identidad gráfica empresarial</b>, en Ki Systems tenemos 
                                la solución de diseño personalizada que usted necesita
                            </p>
                            <a href="https://wa.me/+522222380533" onClick={() => logEvent(analytics, "Click en contactanos")} className='animated bounceIn anim-delay-1s-500ms' style={{animationDuration: "1.3s"}} target="_blank">
                                Contáctanos
                                &nbsp;<b style={{fontSize:"1.5rem", fontWeight:"100"}}>|</b>&nbsp;&nbsp;
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                        </div>
                        <div className='image-container'>
                            <img src={designD} />
                        </div>
                    </div>
                </div>

                <div id='articulos'>
                    <div className='image-container'>
                        <img src={articulos} />
                    </div>
                    <div className='detail'>
                        <div className='info'>
                            <h1 className='animated fadeInUp'>Identidad gráfica</h1>
                            <p className='animated fadeInUp anim-delay-700ms'>Le apoyamos a crear una identidad gráfica <b>moderna</b> y de <b>impacto</b>, desde diseños puntuales para campañas específicas, hasta la creación 
                                de su marca desde cero
                            </p>
                        </div>                        
                    </div>
                </div>

                <div id="varied">
                    <div className="detail">
                        <h1 className='animate__animated animate__fadeInUp'>Diseños en todo tipo de formatos</h1>
                        <p className='animate__animated animate__fadeInUp'>Desde tarjetas, presentaciones, afiches, brochures, lonas, pines y mucho más. Le ayudamos a cuidar la imagen de marca personal o corporativa que transmite</p>
                    </div>
                    <div className="ic-container">
                        <div className="image-car selected" id='ic-a' onClick={(e) => choseGalery(1, e)}></div>
                        <div className="image-car animated heartBeat anim-delay-2s" id='ic-b' onClick={(e) => choseGalery(2, e)}></div>
                        <div className="image-car animated heartBeat anim-delay-2s-500ms" id='ic-c' onClick={(e) => choseGalery(3, e)}></div>
                        <div className="image-car animated heartBeat anim-delay-3s" id='ic-d' onClick={(e) => choseGalery(4, e)}></div>
                    </div>
                </div>

                <footer>
					<p>KiSystems Copyright © 2024 - All rights reserved || Designed By: KiSystems</p>
				</footer>
            </main>
        </React.Fragment>
    );
};

export default Servicios;